import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M4011 8576 c-8 -9 -39 -16 -84 -19 -88 -6 -153 -19 -232 -45 -104
-34 -215 -84 -262 -119 -17 -13 -36 -23 -41 -23 -11 0 -152 -134 -152 -145 0
-3 -11 -21 -25 -39 -14 -18 -25 -40 -25 -49 0 -9 -4 -18 -9 -21 -5 -3 -16 -28
-25 -55 -18 -57 -23 -295 -6 -306 6 -3 10 -18 10 -32 0 -13 6 -37 14 -51 8
-15 24 -47 35 -72 30 -66 68 -128 96 -158 14 -15 25 -32 25 -38 0 -6 57 -69
128 -141 125 -127 275 -256 320 -274 12 -4 22 -12 22 -17 0 -5 12 -15 28 -23
15 -7 50 -30 77 -50 51 -36 81 -54 148 -86 20 -10 37 -21 37 -25 0 -4 19 -17
43 -28 23 -12 53 -28 67 -35 14 -8 54 -28 90 -45 36 -17 79 -40 95 -50 17 -10
40 -19 52 -19 12 -1 25 -5 28 -11 7 -12 95 -53 185 -86 36 -13 74 -28 85 -33
11 -5 40 -16 65 -26 25 -9 53 -21 62 -26 10 -5 25 -9 34 -9 9 0 28 -6 42 -14
44 -23 184 -69 225 -74 56 -6 55 -22 -2 -22 -27 0 -53 5 -56 10 -3 6 -16 10
-28 10 -12 0 -42 6 -67 14 -25 8 -65 20 -90 26 -43 11 -91 25 -225 65 -33 10
-64 22 -70 27 -5 4 -21 8 -36 8 -15 0 -30 4 -33 9 -7 11 -91 38 -131 43 l-30
3 -3 -1094 c-1 -800 1 -1097 9 -1107 16 -19 838 -21 857 -2 9 9 12 212 12 854
l0 841 83 8 c45 4 442 5 882 4 440 -2 806 0 813 4 13 9 17 163 4 183 -4 7 -60
17 -127 24 -66 6 -151 15 -190 20 -38 5 -110 12 -160 16 -49 4 -93 11 -96 17
-4 5 -38 13 -75 18 -129 15 -190 26 -212 38 -11 6 -32 11 -46 11 -27 0 -86 13
-171 36 -27 8 -70 18 -95 24 -122 26 -159 36 -166 43 -4 4 -20 7 -36 7 -16 0
-36 7 -44 15 -9 8 -25 15 -37 15 -23 0 -81 17 -111 32 -27 14 -136 48 -155 48
-9 0 -25 7 -35 15 -11 8 -30 15 -42 15 -12 0 -27 7 -34 15 -6 8 -15 13 -18 12
-9 -2 -59 17 -147 58 -38 18 -88 39 -110 46 -22 7 -44 17 -50 21 -5 4 -17 8
-27 8 -10 0 -21 7 -24 15 -4 8 -12 15 -19 15 -14 0 -235 112 -275 140 -14 9
-32 20 -40 23 -8 4 -28 16 -45 27 -16 11 -37 23 -45 27 -62 28 -261 161 -415
276 -75 56 -181 156 -236 221 -32 39 -68 80 -78 92 -10 11 -22 32 -26 45 -9
31 -32 86 -51 122 -8 16 -14 40 -14 53 0 14 -5 46 -11 71 -9 34 -9 62 0 116
24 141 79 250 178 353 73 76 195 164 226 164 20 0 68 40 62 51 -9 13 -31 11
-44 -5z m1279 -2286 c0 -5 -15 -10 -34 -10 -19 0 -38 5 -41 10 -4 6 10 10 34
10 23 0 41 -4 41 -10z"/>
<path d="M4424 8329 c-2 -4 -4 -253 -4 -554 -1 -439 2 -550 12 -557 60 -40
178 -108 187 -108 6 0 11 -4 11 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -9 0 -8
64 -44 185 -104 33 -16 69 -34 79 -39 105 -56 175 -89 247 -118 46 -18 93 -39
104 -47 11 -7 27 -13 36 -13 9 0 22 -7 29 -15 7 -9 15 -13 18 -11 3 3 17 -1
31 -9 25 -13 87 -37 296 -115 11 -4 37 -15 58 -24 21 -9 44 -16 52 -16 9 0 23
-4 33 -9 21 -10 76 -30 120 -42 17 -5 49 -14 70 -20 20 -6 52 -13 70 -16 18
-3 35 -9 38 -14 3 -5 20 -9 38 -9 18 0 41 -7 52 -15 10 -8 36 -15 56 -15 20 0
40 -4 43 -10 3 -5 19 -10 34 -10 16 0 48 -7 71 -15 23 -8 57 -15 76 -15 18 0
35 -4 38 -9 3 -5 29 -11 58 -14 29 -4 78 -11 108 -17 30 -6 96 -15 145 -20 50
-5 110 -14 135 -19 25 -6 169 -10 321 -11 221 0 285 3 319 15 23 8 59 15 80
15 20 0 50 4 66 9 16 5 54 14 84 21 30 7 62 16 70 21 8 4 42 20 75 35 33 16
74 39 91 51 17 13 35 23 40 23 17 0 114 105 114 123 0 9 5 17 10 17 6 0 17 15
24 33 8 17 21 48 30 69 22 51 22 218 0 261 -9 18 -20 39 -24 47 -17 39 -48 86
-73 114 -16 17 -36 43 -45 59 -9 15 -22 27 -28 27 -7 0 -14 3 -16 8 -1 4 -30
29 -63 57 -33 27 -62 52 -65 55 -14 14 -102 70 -111 70 -6 0 -17 7 -25 15 -16
16 -44 20 -44 8 0 -5 9 -19 20 -33 40 -50 80 -115 80 -132 0 -9 5 -20 10 -23
6 -3 10 -18 10 -33 0 -15 7 -36 15 -46 19 -26 19 -115 0 -140 -8 -11 -15 -28
-15 -38 0 -27 -44 -92 -93 -138 -44 -41 -148 -100 -178 -100 -9 0 -22 -6 -28
-14 -22 -26 -214 -45 -466 -45 -260 0 -624 25 -656 44 -8 5 -39 13 -69 17 -30
3 -77 10 -105 13 -27 4 -64 13 -82 21 -17 8 -40 14 -52 14 -11 0 -44 7 -73 15
-29 8 -82 22 -118 31 -36 9 -81 22 -100 29 -19 7 -55 18 -80 25 -25 7 -60 18
-78 26 -18 8 -41 14 -52 14 -10 0 -22 5 -25 10 -3 6 -15 10 -27 10 -11 0 -32
7 -46 15 -15 8 -65 28 -112 46 -47 17 -110 43 -140 58 -53 27 -55 30 -62 77
-8 62 -10 392 -2 422 l6 22 1013 0 c914 0 1013 2 1019 16 9 23 7 687 -2 701
-6 10 -317 12 -1456 10 -796 -1 -1450 -5 -1452 -8z"/>
<path d="M3018 4242 c-24 -5 -23 -39 2 -47 19 -6 20 -15 20 -176 l0 -169 26 0
c56 0 84 38 44 60 -24 13 -26 45 -4 98 17 43 48 66 85 60 29 -4 34 -46 7 -59
-10 -5 -26 -13 -35 -18 -29 -13 -28 -39 2 -47 18 -4 28 -15 32 -35 8 -40 35
-61 75 -57 28 2 33 7 33 28 0 17 -7 26 -24 30 -33 7 -49 54 -27 76 39 38 32
105 -14 129 -38 20 -58 19 -102 -4 -21 -10 -39 -17 -41 -14 -2 2 -2 35 1 73 2
39 1 71 -4 72 -22 3 -60 4 -76 0z"/>
<path d="M3328 4243 c-25 -6 -23 -44 2 -50 19 -5 20 -14 20 -174 l0 -169 28 0
c37 0 67 22 59 44 -4 9 -13 16 -22 16 -11 0 -15 11 -15 43 0 65 41 117 91 117
53 0 49 -54 -4 -68 -42 -11 -50 -48 -14 -61 14 -6 28 -20 32 -33 16 -50 65
-74 103 -49 21 13 10 44 -18 51 -34 8 -50 53 -27 78 9 10 20 32 23 50 5 26 1
36 -25 63 -29 28 -36 31 -74 25 -23 -4 -49 -14 -59 -23 -16 -14 -18 -14 -24 1
-4 9 -4 39 -2 66 3 27 2 54 -2 61 -8 12 -44 18 -72 12z"/>
<path d="M1554 4225 c-8 -19 3 -32 31 -37 19 -3 20 -13 25 -168 5 -157 6 -165
26 -168 53 -8 102 42 54 55 -18 5 -20 15 -22 167 l-3 161 -53 3 c-41 2 -54 0
-58 -13z"/>
<path d="M4623 4224 c-7 -18 4 -31 30 -36 17 -3 19 -17 24 -158 2 -85 6 -161
9 -169 5 -17 73 -8 86 11 13 21 10 28 -18 38 l-25 10 1 147 c1 82 1 154 1 161
-1 19 -100 16 -108 -4z"/>
<path d="M7386 4225 c-3 -9 -6 -30 -6 -46 0 -22 5 -29 20 -29 22 0 43 40 38
69 -4 22 -44 26 -52 6z"/>
<path d="M8401 4221 c-16 -29 -13 -61 5 -68 22 -8 46 24 42 57 -4 31 -33 38
-47 11z"/>
<path d="M1330 4192 c-12 -4 -13 -311 -1 -330 5 -8 17 -12 27 -10 16 3 19 14
22 81 l3 77 67 0 c75 0 96 9 90 36 -3 16 -14 19 -81 22 l-77 3 0 34 0 35 70 0
c55 0 72 3 77 16 3 8 2 19 -3 24 -9 8 -174 18 -194 12z"/>
<path d="M4100 4166 c0 -21 5 -25 38 -28 l37 -3 5 -140 5 -140 27 -3 27 -3 3
143 3 143 40 5 c32 4 40 9 40 25 0 19 -8 20 -112 23 l-113 3 0 -25z"/>
<path d="M8160 4155 c0 -28 -4 -35 -20 -35 -14 0 -20 -7 -20 -21 0 -12 8 -23
20 -26 18 -5 20 -14 20 -109 0 -122 2 -125 89 -103 44 11 57 19 59 35 4 27
-20 37 -54 24 -14 -6 -27 -10 -30 -10 -2 0 -4 36 -4 80 l0 80 35 0 c31 0 35 3
35 25 0 18 -6 25 -20 26 -11 1 -26 2 -32 3 -7 0 -14 16 -16 34 -3 28 -7 32
-33 32 -27 0 -29 -3 -29 -35z"/>
<path d="M1835 4115 c-44 -20 -63 -38 -75 -69 -39 -104 32 -205 137 -193 56 6
91 30 110 75 30 72 2 155 -64 187 -38 18 -69 19 -108 0z m102 -67 c30 -28 32
-89 3 -118 -25 -25 -86 -27 -112 -3 -23 20 -24 90 -2 121 21 30 79 30 111 0z"/>
<path d="M2068 4123 c-24 -6 -23 -40 2 -48 18 -6 20 -15 20 -116 l0 -110 37 3
c50 5 66 32 34 57 -22 17 -24 23 -18 72 5 39 13 58 29 71 47 36 106 14 110
-40 2 -46 -3 -87 -13 -97 -14 -14 -11 -55 6 -61 20 -8 82 14 89 31 3 8 -1 20
-8 28 -8 7 -15 43 -18 85 -3 58 -8 76 -27 96 -32 34 -84 43 -126 23 -29 -14
-35 -14 -45 -1 -12 14 -37 16 -72 7z"/>
<path d="M2394 4115 c-8 -21 3 -46 18 -43 10 2 14 -25 16 -109 3 -124 5 -127
66 -103 31 11 34 35 6 50 -16 9 -20 21 -20 65 0 46 4 56 26 74 33 26 76 27 97
3 21 -24 22 -103 2 -142 -15 -30 -11 -60 9 -60 6 0 28 5 49 11 30 8 37 15 37
34 0 14 -6 25 -12 25 -9 0 -13 21 -13 74 0 82 -12 104 -67 127 -24 10 -40 10
-77 0 -31 -8 -47 -9 -49 -2 -6 17 -82 13 -88 -4z"/>
<path d="M2823 4123 c-30 -6 -81 -57 -89 -88 -13 -52 2 -116 35 -149 28 -27
38 -31 89 -31 63 0 122 31 122 65 0 27 -25 31 -53 9 -31 -24 -99 -26 -122 -3
-23 23 -12 30 80 48 94 19 109 33 90 79 -23 55 -86 85 -152 70z m83 -67 c25
-19 20 -23 -54 -40 -53 -12 -62 -12 -62 0 0 43 75 69 116 40z"/>
<path d="M3678 4118 c-37 -10 -56 -34 -39 -51 5 -5 7 -35 4 -66 -10 -103 42
-158 139 -149 54 6 64 -9 26 -36 -30 -21 -60 -20 -91 4 -30 24 -53 26 -61 5
-20 -54 87 -94 173 -64 52 18 61 49 61 214 0 163 -2 167 -65 141 -41 -18 -46
-49 -7 -54 20 -3 22 -9 22 -66 0 -66 -7 -79 -48 -89 -90 -22 -131 94 -61 169
18 19 20 26 9 39 -7 8 -15 15 -19 14 -3 -1 -23 -5 -43 -11z"/>
<path d="M4430 4111 c-46 -24 -73 -72 -72 -128 0 -50 32 -105 70 -122 74 -34
212 26 171 75 -10 13 -15 12 -37 -2 -41 -27 -76 -33 -111 -18 -18 8 -31 19
-29 26 3 7 45 22 94 33 71 16 90 24 93 40 5 30 -28 82 -64 99 -43 21 -73 20
-115 -3z m94 -47 c46 -18 -27 -60 -91 -52 l-25 3 22 28 c21 27 58 35 94 21z"/>
<path d="M4891 4113 c-52 -26 -73 -67 -68 -136 3 -49 8 -63 34 -88 20 -21 43
-32 71 -36 64 -9 142 29 142 68 0 29 -18 32 -54 10 -38 -24 -94 -27 -121 -8
-28 21 -12 34 62 47 95 18 113 26 113 51 0 52 -64 109 -122 109 -12 0 -38 -8
-57 -17z m93 -49 c20 -8 21 -30 2 -37 -7 -3 -34 -9 -60 -12 -38 -6 -46 -5 -46
8 0 33 62 57 104 41z"/>
<path d="M5190 4117 c-98 -33 -109 -191 -17 -247 41 -26 103 -25 145 0 40 25
50 47 29 67 -14 14 -18 14 -43 -6 -37 -29 -98 -29 -118 1 -9 12 -16 38 -16 58
0 53 23 80 69 80 27 0 46 -7 66 -26 37 -34 56 -17 48 42 l-5 42 -62 0 c-33 0
-77 -5 -96 -11z"/>
<path d="M5475 4114 c-56 -32 -75 -64 -75 -126 0 -107 103 -170 199 -120 79
40 94 154 30 218 -42 42 -107 54 -154 28z m109 -59 c11 -8 20 -31 23 -58 7
-57 -19 -87 -74 -87 -49 0 -61 9 -73 49 -10 37 1 79 27 99 22 16 73 15 97 -3z"/>
<path d="M6718 4117 c-19 -7 -28 -18 -28 -34 0 -13 4 -23 9 -23 5 0 7 -27 4
-60 -7 -72 9 -115 48 -135 34 -18 91 -19 107 -3 10 10 17 10 32 0 25 -16 50
-15 51 1 5 40 -4 253 -10 260 -12 12 -86 -16 -86 -33 0 -8 10 -19 23 -25 21
-9 23 -16 20 -69 -3 -56 -5 -61 -36 -79 -28 -16 -37 -17 -62 -7 -19 8 -31 20
-32 34 -3 44 3 82 19 118 26 61 5 81 -59 55z"/>
<path d="M7002 4118 c-18 -18 -15 -36 8 -43 18 -6 20 -15 20 -110 0 -57 4
-107 10 -110 5 -3 25 -3 45 1 28 5 35 11 35 30 0 15 -6 24 -15 24 -12 0 -15
14 -15 65 0 61 1 65 30 80 50 26 79 20 96 -20 15 -36 10 -110 -7 -121 -5 -3
-9 -18 -9 -35 0 -34 20 -38 75 -15 37 16 47 45 18 52 -15 4 -18 17 -18 79 0
64 -3 77 -24 99 -30 32 -69 40 -121 25 -31 -9 -43 -10 -52 -1 -16 16 -61 15
-76 0z"/>
<path d="M7577 4115 c-53 -27 -77 -65 -77 -120 0 -47 22 -98 51 -119 50 -35
137 -33 177 5 44 40 15 85 -31 48 -59 -45 -137 -11 -137 61 0 50 30 80 79 80
30 0 42 -5 55 -25 14 -21 20 -24 37 -15 16 9 19 19 16 53 l-4 42 -69 2 c-45 2
-79 -2 -97 -12z"/>
<path d="M7874 4116 c-53 -23 -74 -60 -74 -130 0 -40 6 -70 16 -84 32 -46 105
-65 158 -41 20 9 29 9 40 0 19 -16 73 -5 85 17 12 23 3 42 -20 42 -17 0 -19 8
-19 99 0 79 -3 100 -15 105 -17 6 -45 -2 -45 -15 0 -4 -16 -1 -35 7 -42 17
-51 17 -91 0z m105 -72 c36 -46 24 -106 -26 -132 -32 -18 -51 -15 -78 13 -36
35 -31 111 9 137 26 18 75 8 95 -18z"/>
<path d="M8590 4112 c-71 -35 -98 -116 -63 -189 31 -66 116 -93 184 -58 94 49
92 203 -4 248 -45 22 -73 21 -117 -1z m110 -60 c46 -37 21 -137 -35 -142 -62
-6 -95 21 -95 77 0 71 76 109 130 65z"/>
<path d="M8833 4123 c-20 -7 -15 -37 7 -51 18 -11 20 -23 20 -117 0 -97 1
-105 19 -105 59 0 91 38 51 60 -31 17 -28 103 5 135 31 32 78 33 99 3 20 -29
20 -88 1 -135 -21 -50 -5 -67 51 -55 33 6 40 12 42 35 2 19 -1 27 -12 27 -12
0 -14 9 -9 49 14 117 -69 194 -159 147 -23 -12 -29 -12 -39 0 -11 14 -51 18
-76 7z"/>
<path d="M9223 4123 c-34 -6 -65 -52 -58 -83 9 -37 34 -56 96 -74 31 -9 59
-23 63 -31 13 -36 -86 -44 -114 -10 -25 30 -57 12 -45 -24 15 -47 125 -66 183
-31 26 16 32 26 32 53 0 48 -18 66 -90 91 -74 25 -90 50 -39 61 24 5 38 2 61
-15 39 -29 58 -20 58 28 l0 39 -62 0 c-35 -1 -73 -2 -85 -4z"/>
<path d="M5707 4106 c-6 -16 3 -31 22 -37 11 -4 13 -27 12 -103 -1 -56 2 -103
8 -110 13 -16 66 -9 82 10 14 17 -4 44 -28 44 -24 0 -8 120 19 143 26 22 62
22 83 0 14 -13 16 -28 11 -77 -13 -120 -13 -121 13 -124 40 -6 71 7 71 29 0
11 -6 22 -14 25 -12 5 -13 20 -9 77 3 40 11 75 17 79 21 14 56 8 77 -13 19
-19 21 -29 16 -77 -3 -30 -11 -60 -16 -65 -16 -16 -13 -47 4 -53 8 -4 31 -3
50 0 25 5 35 12 35 26 0 10 -5 22 -11 25 -6 5 -8 26 -4 59 8 62 -11 116 -49
141 -19 13 -38 16 -78 11 -29 -3 -60 -6 -68 -5 -8 0 -65 2 -127 4 -85 3 -112
1 -116 -9z"/>
<path d="M6194 4105 c-7 -18 3 -35 21 -35 11 0 15 -23 17 -107 l3 -108 30 -3
c38 -4 68 17 59 41 -3 9 -15 17 -26 17 -20 0 -20 4 -14 68 7 73 21 92 67 92
48 0 62 -25 55 -94 -13 -120 -13 -121 13 -124 39 -6 71 7 71 28 0 10 -6 23
-14 27 -11 6 -13 23 -8 83 7 72 8 75 34 78 67 8 93 -50 64 -139 -10 -28 -15
-58 -12 -65 6 -14 52 -19 80 -8 18 7 21 39 5 49 -7 5 -8 25 -4 56 9 62 -8 113
-46 140 -25 17 -39 20 -82 15 -29 -3 -59 -6 -67 -5 -8 0 -66 2 -128 4 -91 3
-114 1 -118 -10z"/>
<path d="M7324 4105 c-8 -20 4 -35 26 -35 18 0 20 -9 22 -107 l3 -108 27 -3
c53 -6 96 43 48 55 -18 5 -20 14 -20 109 l0 104 -50 0 c-35 0 -52 -4 -56 -15z"/>
<path d="M8333 4104 c-7 -18 4 -31 32 -36 18 -3 20 -13 25 -108 5 -114 10
-121 63 -101 33 13 38 51 7 51 -19 0 -20 7 -20 105 l0 105 -50 0 c-37 0 -52
-4 -57 -16z"/>
<path d="M4327 3754 c-13 -14 -7 -32 14 -37 15 -4 20 -12 17 -28 -3 -21 -8
-23 -49 -21 -38 3 -51 -1 -68 -19 -30 -33 -36 -80 -16 -123 20 -42 37 -48 123
-43 70 4 98 20 70 41 -14 10 -18 33 -20 122 l-3 109 -30 3 c-17 2 -34 0 -38
-4z m22 -156 c22 -39 0 -78 -44 -78 -17 0 -36 5 -43 12 -15 15 -16 53 -1 79
16 30 69 22 88 -13z"/>
<path d="M7481 3744 c-33 -23 -41 -74 -28 -174 9 -74 14 -90 28 -90 46 0 68
33 29 43 -17 4 -20 14 -20 55 0 45 3 51 22 54 13 2 24 11 26 21 3 13 -3 17
-23 17 -22 0 -26 4 -23 23 3 21 13 24 48 16 9 -3 15 3 15 15 0 27 -47 39 -74
20z"/>
<path d="M3598 3733 c-3 -10 -3 -24 -1 -30 5 -14 33 -18 33 -5 0 4 3 17 6 30
5 17 2 22 -14 22 -11 0 -22 -8 -24 -17z"/>
<path d="M7167 3743 c-4 -3 -7 -17 -7 -30 0 -35 34 -26 38 10 3 26 -14 37 -31
20z"/>
<path d="M8217 3724 c-3 -16 0 -28 10 -31 17 -7 20 -4 28 30 6 23 4 27 -13 27
-13 0 -21 -8 -25 -26z"/>
<path d="M3710 3696 c0 -16 -6 -26 -15 -26 -8 0 -15 -9 -15 -20 0 -11 7 -20
15 -20 12 0 15 -15 15 -75 0 -73 1 -75 24 -75 38 0 76 18 76 36 0 13 -6 15
-30 11 l-30 -6 0 54 c0 48 2 54 22 57 33 4 37 38 4 38 -18 0 -25 6 -28 22 -5
34 -38 37 -38 4z"/>
<path d="M3867 3714 c-4 -4 -7 -14 -7 -22 0 -8 -8 -21 -17 -28 -17 -13 -17
-15 2 -29 22 -18 22 -17 24 -97 1 -54 3 -58 25 -58 37 0 78 18 73 33 -2 6 -16
12 -30 12 -26 -1 -27 1 -27 51 0 47 2 53 23 56 28 4 29 32 0 36 -13 2 -23 11
-25 25 -3 22 -28 34 -41 21z"/>
<path d="M4590 3696 c0 -16 -6 -26 -15 -26 -8 0 -15 -9 -15 -19 0 -10 7 -21
15 -25 11 -4 15 -22 15 -76 0 -68 1 -70 24 -70 38 0 76 18 76 36 0 13 -6 15
-30 11 l-30 -6 0 54 c0 48 2 54 23 57 30 4 28 32 -3 38 -17 3 -25 12 -25 25 0
11 -8 21 -17 23 -14 3 -18 -3 -18 -22z"/>
<path d="M6290 3696 c0 -16 -6 -26 -15 -26 -8 0 -15 -9 -15 -19 0 -10 7 -21
15 -25 11 -4 15 -22 15 -76 0 -68 1 -70 24 -70 38 0 76 18 76 36 0 13 -6 15
-30 11 l-30 -6 0 54 c0 48 2 54 22 57 33 4 37 38 4 38 -18 0 -25 6 -28 22 -5
34 -38 37 -38 4z"/>
<path d="M7000 3702 c0 -11 -10 -27 -22 -36 l-23 -16 23 -16 c19 -14 22 -25
22 -85 0 -66 1 -69 24 -69 37 0 78 18 73 33 -2 6 -16 11 -31 10 -25 -2 -26 0
-26 52 0 48 2 54 22 57 33 4 37 38 4 38 -18 0 -25 6 -28 22 -4 29 -38 38 -38
10z"/>
<path d="M8054 3698 c-4 -13 -14 -28 -22 -34 -14 -10 -13 -14 6 -30 19 -16 22
-29 22 -86 0 -65 1 -68 24 -68 38 0 76 18 76 35 0 12 -8 15 -32 13 l-33 -3 -3
53 c-3 49 -2 52 21 52 14 0 27 6 30 14 7 19 -1 26 -30 26 -18 0 -23 5 -23 25
0 32 -26 33 -36 3z"/>
<path d="M2540 3673 c-65 -6 -80 -13 -95 -43 -35 -68 3 -150 70 -150 46 0 85
19 85 42 0 20 -29 25 -47 7 -20 -20 -67 -6 -77 23 -15 44 6 78 48 78 21 0 36
-6 40 -15 3 -8 13 -15 21 -15 13 0 15 9 13 37 -3 40 -6 41 -58 36z"/>
<path d="M2956 3672 c-3 -3 -26 -4 -51 -4 -33 2 -45 -2 -45 -12 0 -8 7 -17 16
-20 13 -5 15 -19 13 -79 l-4 -72 30 0 c20 0 31 5 33 18 2 10 -3 17 -12 17 -12
0 -16 11 -16 44 0 50 16 69 57 64 26 -3 28 -6 26 -48 -1 -25 -3 -56 -5 -70 -3
-22 1 -25 27 -25 l30 0 -4 73 -3 72 35 0 c42 0 51 -22 37 -85 -14 -59 -12 -65
14 -65 28 0 53 24 36 35 -7 5 -9 20 -6 41 9 46 -15 101 -49 110 -14 3 -42 4
-60 1 -19 -3 -48 -2 -64 3 -16 4 -32 6 -35 2z"/>
<path d="M3414 3670 c-10 -4 -54 -5 -96 -2 -42 2 -84 2 -94 -2 -15 -6 -16 -8
0 -24 12 -14 16 -38 16 -91 l0 -72 27 3 c31 3 47 38 17 38 -14 0 -16 7 -10 46
8 60 11 64 51 64 39 0 50 -26 35 -81 -17 -58 -13 -69 20 -69 32 0 53 26 32 39
-7 4 -10 28 -8 56 2 50 3 50 37 50 34 0 34 0 33 -45 -1 -25 -5 -58 -9 -73 -7
-27 -6 -28 26 -25 l34 3 -3 70 c-3 52 -9 75 -24 91 -22 25 -55 34 -84 24z"/>
<path d="M6625 3671 c-64 -8 -75 -16 -75 -50 0 -34 12 -45 68 -62 54 -16 56
-39 3 -39 -22 0 -41 5 -43 12 -2 6 -12 9 -21 5 -22 -8 -11 -36 20 -48 63 -24
123 0 123 48 0 25 -16 39 -65 56 -22 8 -41 19 -43 25 -6 20 26 25 59 8 18 -9
34 -16 36 -16 1 0 3 16 3 35 0 19 -3 34 -7 34 -5 -1 -30 -5 -58 -8z"/>
<path d="M7330 3672 c-102 -8 -109 -85 -10 -112 51 -14 54 -40 6 -40 -19 0
-38 5 -41 10 -9 15 -35 12 -35 -3 0 -25 36 -47 74 -47 53 0 76 15 76 49 0 33
-15 48 -67 66 -21 7 -39 17 -41 23 -6 18 30 22 61 7 35 -17 50 -9 45 26 -3 26
-7 27 -68 21z"/>
<path d="M7881 3667 c-40 -10 -61 -42 -61 -93 0 -55 37 -94 89 -94 40 0 81 23
81 46 0 16 -36 19 -45 4 -9 -14 -58 -13 -73 2 -7 7 -12 26 -12 43 0 17 5 36
12 43 17 17 63 15 78 -3 24 -29 41 -18 38 23 -3 36 -4 37 -40 37 -21 0 -51 -3
-67 -8z"/>
<path d="M2687 3659 c-53 -31 -58 -121 -9 -160 36 -28 103 -22 132 12 29 36
27 103 -5 134 -26 27 -84 34 -118 14z m91 -41 c7 -7 12 -26 12 -43 0 -39 -16
-55 -56 -55 -26 0 -34 6 -44 30 -19 46 -1 80 43 80 18 0 38 -5 45 -12z"/>
<path d="M3560 3656 c0 -8 7 -16 15 -19 10 -4 15 -26 17 -79 l3 -73 30 0 c20
0 31 5 33 18 2 10 -3 17 -12 17 -13 0 -16 13 -16 75 l0 75 -35 0 c-23 0 -35
-5 -35 -14z"/>
<path d="M4043 3664 c-7 -3 -22 -21 -34 -40 -55 -89 36 -178 131 -129 29 15
38 34 23 48 -3 4 -17 0 -30 -9 -27 -17 -84 -15 -90 3 -3 7 8 15 24 19 115 26
124 35 82 88 -16 20 -29 26 -57 25 -20 0 -42 -3 -49 -5z m77 -48 c0 -8 -15
-17 -36 -21 -40 -7 -49 -3 -41 20 8 20 77 21 77 1z"/>
<path d="M4751 3649 c-70 -65 -34 -169 59 -169 54 0 90 38 90 97 0 33 -6 50
-25 68 -31 32 -91 34 -124 4z m97 -31 c7 -7 12 -26 12 -43 0 -38 -16 -55 -50
-55 -34 0 -50 17 -50 55 0 38 16 55 50 55 14 0 31 -5 38 -12z"/>
<path d="M5040 3655 c0 -7 7 -15 15 -19 12 -4 15 -27 15 -121 0 -92 3 -115 14
-115 16 0 26 21 26 59 0 25 2 26 41 23 32 -3 46 1 65 18 42 41 44 111 2 150
-17 15 -35 19 -99 18 -58 0 -79 -4 -79 -13z m160 -46 c13 -23 5 -70 -13 -81
-21 -14 -65 -9 -71 8 -10 26 -7 69 6 82 20 20 66 15 78 -9z"/>
<path d="M5300 3574 l0 -95 32 3 c34 3 55 38 24 38 -23 0 -23 85 0 94 29 11
44 6 44 -15 0 -36 35 -19 38 20 5 46 -8 56 -54 41 -28 -10 -39 -10 -42 -1 -2
6 -12 11 -23 11 -17 0 -19 -8 -19 -96z"/>
<path d="M5495 3644 c-35 -38 -35 -105 0 -139 31 -32 91 -34 128 -5 48 38 41
131 -12 160 -37 19 -90 12 -116 -16z m109 -30 c10 -26 7 -69 -6 -82 -7 -7 -24
-12 -38 -12 -14 0 -31 5 -38 12 -13 13 -16 56 -6 82 8 22 80 22 88 0z"/>
<path d="M5680 3655 c0 -7 7 -15 15 -19 12 -4 15 -23 15 -81 l0 -76 27 3 c30
3 47 38 19 38 -12 0 -16 10 -16 38 0 51 18 75 55 70 28 -3 30 -5 30 -48 -1
-25 -3 -56 -5 -70 -4 -22 -1 -25 27 -28 l32 -3 -6 75 -6 76 36 0 c31 0 38 -4
43 -26 4 -14 2 -43 -5 -64 -6 -20 -11 -42 -11 -49 0 -12 29 -15 54 -5 17 7 22
34 6 34 -5 0 -7 20 -4 48 5 38 2 52 -15 73 -21 27 -22 27 -156 27 -105 0 -135
-2 -135 -13z"/>
<path d="M6025 3662 c-3 -3 2 -13 10 -22 10 -11 15 -46 17 -125 2 -90 6 -110
18 -110 10 0 16 12 18 38 3 36 4 37 45 37 23 0 49 6 58 13 45 34 53 114 15
152 -21 21 -32 24 -100 23 -41 -1 -78 -3 -81 -6z m157 -57 c17 -38 0 -79 -35
-83 -50 -6 -73 39 -47 89 16 29 67 26 82 -6z"/>
<path d="M6754 3645 c-19 -19 -24 -35 -24 -74 0 -42 4 -53 26 -70 23 -18 37
-20 103 -17 42 2 77 5 79 5 9 4 -1 33 -13 37 -11 5 -15 23 -15 74 l0 67 -66 1
c-57 1 -69 -2 -90 -23z m98 -32 c38 -35 15 -93 -38 -93 -27 0 -36 5 -44 25
-15 39 6 85 38 85 14 0 34 -7 44 -17z"/>
<path d="M7120 3657 c0 -8 7 -17 15 -20 10 -4 15 -26 17 -79 l3 -73 30 0 c20
0 31 5 33 18 2 11 -3 17 -13 17 -15 0 -17 10 -14 73 l4 72 -37 3 c-28 2 -38
-1 -38 -11z"/>
<path d="M7614 3662 c-42 -29 -55 -92 -29 -142 18 -35 38 -41 122 -37 72 3 98
19 67 41 -15 12 -19 27 -19 77 l0 63 -65 3 c-36 1 -70 -1 -76 -5z m86 -51 c25
-47 6 -91 -40 -91 -34 0 -50 17 -50 55 0 17 5 36 12 43 19 19 66 15 78 -7z"/>
<path d="M8180 3656 c0 -8 7 -16 15 -19 10 -4 15 -26 17 -79 l3 -73 30 0 c20
0 31 5 33 18 2 10 -3 17 -12 17 -13 0 -16 13 -16 75 l0 75 -35 0 c-23 0 -35
-5 -35 -14z"/>
<path d="M8338 3647 c-34 -32 -42 -60 -28 -102 14 -43 44 -65 88 -65 46 0 71
16 87 54 18 41 4 98 -28 121 -33 23 -90 19 -119 -8z m100 -29 c7 -7 12 -26 12
-43 0 -38 -16 -55 -51 -55 -32 0 -51 21 -51 55 0 14 5 32 12 40 15 18 61 20
78 3z"/>
<path d="M8520 3655 c0 -7 7 -15 15 -19 11 -4 15 -23 15 -81 0 -74 0 -75 25
-75 32 0 42 24 20 46 -9 9 -14 23 -11 31 3 8 6 25 6 37 0 29 42 51 71 36 14
-8 19 -21 19 -53 0 -24 -5 -48 -11 -54 -16 -16 -4 -43 19 -43 10 0 27 6 38 14
15 12 17 17 7 27 -7 7 -13 34 -13 59 0 73 -21 88 -120 88 -58 0 -80 -4 -80
-13z"/>
<path d="M2082 3528 c-19 -19 -14 -48 8 -48 15 0 20 7 20 24 0 30 -12 40 -28
24z"/>
<path d="M2172 3528 c-19 -19 -14 -48 8 -48 15 0 20 7 20 24 0 30 -12 40 -28
24z"/>
<path d="M1980 3504 c0 -33 33 -30 38 4 3 17 -2 22 -17 22 -16 0 -21 -6 -21
-26z"/>
<path d="M2254 3515 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
8z"/>
<path d="M2346 3514 c-4 -10 -2 -22 3 -27 14 -14 43 0 39 20 -4 24 -34 28 -42
7z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
